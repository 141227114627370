const {fr} = require("date-fns/locale");
const { registerLocale, setDefaultLocale } = require("react-datepicker");

registerLocale('fr', fr);
setDefaultLocale('fr');

module.exports = {
  "api_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MjI3MDA0NzQsImV4cCI6MTY1NDIzNjQ3NCwidXNlcm5hbWUiOiIzOSJ9.zwtl76to23UQCkU0YZ2_nXhraXHiTecT01Jm2jCqEi8J6DDWtuU7ZSKIsHcmLkeBq7vp5EDQZgnemlEV9hwp-lLZ6bsTeqju0PmZO2c0I3DxDAKtqr5xHMtDW1qwqXTo6rs0CiAyFpYNkIoWQOK2Um7B19Vf4x5wMHhA76FOLcRTX1KmiJtShv6jekXdGqzusQjxFBgEacZJcHrbA58i0l-s-uyPxNV6i9wm9NWwHiVcziZdeqspNs1gGEkJYpfyqFYsnmUf7T31gGtK0RtOyjvAoPCuWVCMdNi-jgHdbTkMNTJ6IM2flKhR0LcBk-4Vqr9rEJcA8rHOxIPmPffVVy0XBAoLE3Zw5u9jgpv4saONqCVubw1y6Ix9DKfa_gNTbYcrVD9ZGcQph882nNY5hBgAuHt7PuJgNhKGVHrxs2_hHs3KzxW2ZxlpbgB56MfvV85V8qTzHfckAOuV2HKzUGze2bdcaVrLVOJAgziZRymqpkSfSLmc0rCsiAPUb5JVr66GguTiZefoj4A-riya71-HaCovKaZOO6gFT_vJy84LC1qI1M7T64pb-CcPymQDsrH_SvOVFdZNhPzsbDkU8EaPw6J5csO4_OjlGpINOlYkdA5BWQUBgEFBXkZcIGMv9Zmcp-xTewP4j1RGRnY7YeeIoyNw-uv3HfWF6T5fzE0",
  "date_format": {
    "fr": "dd/MM/yyyy"
  },
  "display_contact": true,
  "fb_display": false,
  "info_traffic_title": {
    "fr": "Toute l'info trafic"
  },
  "last_news_label": {
    "fr": "Actualités"
  },
  "mapbox_token": "pk.eyJ1IjoibWltaXJvc2UyOSIsImEiOiJjbGV3cHhsaGgxMHV2NDNtazAxOGM0MXhrIn0.LieDdLfJevyJ6njBc9-vQg",
  "notifications_service_endpoint": "https://apim.keolis.com/KWP000/notification",
  "search_title": {
    "fr": "Recherche"
  },
  "side_blocks": {
    "fr": [
      "137048366"
    ]
  },
  "api_url": "https://api-v2.mobireport.fr",
  "contact_label": {
    "fr": "Renseignements"
  },
  "info_traffic_slug": {
    "fr": "toutes-les-infos-trafic"
  },
  "news_redirect_label": {
    "fr": "Lire l'actualité"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/KWP000/file/import",
  "search_button_label": {
    "fr": "Rechercher"
  },
  "serverless_sendmail_endpoint": "https://apim.keolis.com/dato/send/form",
  "time_format": {
    "fr": "HH:mm"
  },
  "twitter_display": false,
  "website_name": "SÈTE AGGLOPÔLE MÉDITERRANÉE",
  "contact_phone_number": {
    "fr": "04 67 53 01 01"
  },
  "info_traffic_detail_slug": {
    "fr": "Détail de l'info trafic"
  },
  "no_search_label": {
    "fr": "Veuillez saisir des termes et valider pour lancer la recherche"
  },
  "notifications_service_token": "1142321004f3407b98a95856f9820607",
  "primary_color": {
    "red": 71,
    "blue": 178,
    "alpha": 255,
    "green": 127
  },
  "serverless_sendmail_token": "1142321004f3407b98a95856f9820607",
  "alt_text": {
    "fr": "Renseignements : 04 67 53 01 01"
  },
  "gradient_primary_color": {
    "red": 106,
    "blue": 254,
    "alpha": 255,
    "green": 183
  },
  "info_traffic_content_title": {
    "fr": "L'information trafic"
  },
  "search_in_progress_label": {
    "fr": "Recherche en cours..."
  },
  "gradient_button_color": {
    "red": 89,
    "blue": 177,
    "alpha": 255,
    "green": 178
  },
  "redirection_button_text": {
    "fr": "+ D'INFOS"
  },
  "search_no_results_label": {
    "fr": "Il n'y a aucun résultat pour cette recherche"
  },
  "active_button_text_color": {
    "red": 255,
    "blue": 255,
    "alpha": 255,
    "green": 255
  },
  "search_error_label": {
    "fr": "Une erreur s'est produite, veuillez réessayer dans quelques minutes"
  },
  "search_token": "57f88f6fd4891fa919d6dcb98ca5f1",
  "secondary_color": {
    "red": 255,
    "blue": 255,
    "alpha": 255,
    "green": 255
  },
  "subsidiary_logo": {
    "path": "/79658/1693322988-sete-agglopole-mobilite-blanc.png",
    "format": "png",
    "size": 10509,
    "alt": "Logo Sète Agglopôle Mobilité",
    "title": "Sète Agglopôle Mobilité",
    "width": 221,
    "height": 221
  },
  "back_to_home_title": {
    "fr": "SÈTE AGGLOPÔLE MÉDITERRANÉE – revenir à la page d'accueil"
  },
  "language_selector_title": {
    "fr": "Changer la langue"
  },
  "piano_site_id": "639202",
  "google_tag_manager_id": "GTM-WKZ3NN3",
  "google_analytics_key": "",
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "actistat_key": "",
  "loader": {
    "path": "/79658/1693502925-loader-bassin-de-thau.gif",
    "format": "gif",
    "size": 859895,
    "alt": "Page en chargement, veuillez patientez s'il vous plaît",
    "title": "Page en chargement",
    "width": 1080,
    "height": 1080
  },
  "api_url_get_lines_endpoint": "/public/line",
  "api_url_user_registration_endpoint": "/public/customer",
  "scripts": [
    "10670241"
  ],
  "form_captcha_key": "6Lcd1DMjAAAAAEsHVSyCqBSA_c8IBpjJStjgY_pH",
  "updated_at": "2024-10-14T14:04:51.268+02:00",
  "created_at": "2020-12-29T15:46:21.809+01:00",
  "locale": "fr",
  "locales": [
    "fr"
  ],
  "siteUrl": "https://www.mobilite.agglopole.fr",
  "readOnlyApi": "aa5664af2f22183887d7af92d0639c"
}